import { PageNotFound, Permission } from '@campxdev/shared'
import {
  BundleView,
  BundlesAssigned,
  ExamsView,
  InternalExamBundleView,
  InternalExamBundles,
} from 'pages'

import Bundles from 'pages/ExternalExam/PhysicalEvaluation'
import { BundleMarksView } from 'pages/ExternalExam/PhysicalEvaluation/BundleMarksView'
import { Navigate } from 'react-router-dom'

export const baseRoutes = [
  {
    index: true,
    element: <Navigate to="digital" />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'digital',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
    children: [
      {
        index: true,
        element: <Navigate to="bundles-assigned" />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
      {
        path: 'bundles-assigned',
        element: <BundlesAssigned />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
      {
        path: 'bundles-assigned/:bundleId',
        element: <BundleView />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
    ],
  },
  {
    path: 'manual/bundles',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
    element: <Bundles />,
    children: [
      {
        path: ':bundleId',
        element: <BundleMarksView />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
    ],
  },
  {
    path: 'exams-view',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
    element: <ExamsView />,
  },
  {
    path: 'internal',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
    children: [
      {
        index: true,
        element: <Navigate to="bundles" />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
      {
        path: 'bundles',
        element: <InternalExamBundles />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
      {
        path: 'bundles/:bundleEvaluatorId',
        element: <InternalExamBundleView />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
    ],
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]
